export const enum AuthEndpoints {
  LOGIN = "/login",
  LOGOUT = "/logout",
  PASSWORD_EMAIL = "/password/email",
  PASSWORD_RESET = "/password/reset",
  PASSWORD_CHANGE = "/password/change",
  ADMIN_LOGIN = "/admin-login"
}

export const enum ProfileEndpoints {
  PROFILE = "/profile",
  CHANGE_FIO = "/profile/change-fio",
  CHANGE_EMAIL = "/profile/change-email",
}

export const enum StaffEndpoints {
  STAFF = "/staff",
  DOWNLOAD = "/download/power-attorney",
}

export const enum RoleAndPermissionEndpoints {
  ROLES_PERMISSIONS = "/user/roles-permissions",
  ROLES = "/user/roles",
  PERMISSIONS = "/user/permissions",
  LIST_ROLES = "/user/list-roles",
  LIST_PERMISSIONS = "/user/list-permissions",
  PERMISSIONS_FOR_ROLE = "/user/list-permissions-for-role",
}

export const enum FeedbackEndpoints {
  SECURITY_SERVICE = "/feedback/security-service",
  TECH_SUPPORT = "/feedback/tech-support",
}

export const enum OnboardingEndpoints {
  USER_SCREENS = "/onboarding/user-screens",
  SINGLE_SCREEN = "/onboarding/screens",
  DOWNLOAD_ALL_SCREENS = "onboarding/download/user-pdf",
  DOWNLOAD_SINGLE_SCREEN = "onboarding/download/pdf",
  ONBOARDING_COMPLETE = "onboarding/complete",
  SCREENS_BY_PERMISSION = "/onboarding/screens"
}

export const enum ContractsEndpoints {
  CONTRACTS = "/contracts",
  ARHIVE_CONTRACTS = "/archive-contracts",
  SINGLE_CONTRACT = "/contract",
}

export const enum AccessActsEndpoints {
  ACCESS_ACTS = "/access-acts",
  SINGLE_ACCESS_ACT = "/access-act",
}

export const enum InvoicesEndpoints {
  INVOICES = "/invoices",
  INVOICES_BY_CONTRACT = "/invoices-by-contract",
  SINGLE_INVOICE = "/invoice",
  APPROVE_INVOICE ="/approve"
}

export const enum PaymentsEndpoints {
  PAYMENTS = "/payments",
  SINGLE_PAYMENT = "/payment",
}

export const enum SavingsCompensationActsEndpoints {
  SAVINGS_COMPENSATION_ACTS = "/savings-compensation-acts",
  SAVINGS_COMPENSATION_ACTS_BY_PROJECT = "/savings-compensation-acts-by-project",
  SAVINGS_COMPENSATION_ACTS_BY_CONTRACT = "/savings-compensation-acts-by-contract",
  SINGLE_SAVINGS_COMPENSATION_ACT = "/savings-compensation-act",
  CREATE_COMPENSATION_ACT = "/savings-compensation-act/create",
}

export const enum LimitFenceCardsEndpoints {
  LIMIT_FENCE_CARDS = "/limit-fence-cards",
  LIMIT_FENCE_CARDS_BY_PROJECT = "/limit-fence-cards-by-project",
  LIMIT_FENCE_CARDS_BY_CONTRACT = "/limit-fence-cards-by-contract",
  SINGLE_LIMIT_FENCE_CARD = "/limit-fence-card",
  CREATE_LIMIT_FENCE_CARD = "/limit-fence-card/create",
  APPROVE_LIMIT_FENCE_CARD = "/limit-fence-card/approve",
  UPDATE_LIMIT_FENCE_CARD = "/limit-fence-card/update",
}

export const enum DirectionLettersEndpoints {
  DIRECTION_LETTERS = "/direction-letters",
  DIRECTION_LETTERS_BY_LIMIT_FENCE_CARD = "/direction-lettersby-by-limit-fence-card",
  DIRECTION_LETTERS_BY_CONTRACT = "/direction-letters-by-contract",
  DIRECTION_LETTER = "/direction-letter"
}

export const enum WorkCompletedActsEndpoints {
  WORK_COMPLETED_ACTS = "work-completed-acts",
  CREATE_WORK_COMPLETED_ACT = "work-completed-act/create",
  SINGLE_WORK_COMPLETED_ACT ="work-completed-act"
}

export const enum EstimatesEndpoints {
  ESTIMATES = "contract",
  ESTIMATE_DETAILS = "estimates",
  SINGLE_ESTIMATE = "estimate",
  COMPLETE_INFO = "complete-info",
  ESTIMATES_AND_SPRECIFICATIONS = '/estimates-and-specifications'
}

export const enum FaqEndpoints {
  FAQS = "faqs"
}
export const enum VatsEndpoints {
  VATS = "vats"
}

export const enum Files {
  FILE = "/file",
  ATTACH_FILES = "/attach-files",
  FILES = "/files",
  REQUESTED_FILE = "/requested-file"
}

export const enum AdditionalRequestsEndpoints {
  ADDITIONAL_REQUESTS = "/additional-requests",
  ADDITIONAL_REQUEST = "/additional-request",
  CREATE = "/create",
  APPROVE = "/approve",
  ANSWER = "/answer",
}

export const enum ReportsEndpoints {
  REPORT_CONTRACT = "/report-contract",
  REPORT_ESTIMATE = "/report-estimate",
  REPORT_LIMIT_CARD = "/report-limit-card",
}

export const enum RemarksEndpoints {
  REMARKS_CONSTRUCTION = "/construction-control-notes",
  REMARK_CONSTRUCTION = "/construction-control-note",
  REMARKS_TRANSMISSION = "/transfer-notes",
  REMARK_TRANSMISSION = "/transfer-note"
}

export const enum RequirementsEndpoints {
  REQUIREMENTS = "/requirements",
  REQUIREMENT = "/requirement"
}

export const enum ReconciliationStatementEndpoints {
  REQ_STATE = "/reconciliation-statement",
}

export const enum SigningEndpoints {
  SIGNING = "/signings",
}

export const enum SettlementsEndpoints {
  SETTLEMENTS = "/mutual-settlements",
  SETTLEMENT = "/mutual-settlement",
}

export const enum IndexEndpoints {
  INDEX = "/index",
  STATUS = "/status"
}

export const enum TooltipEndpoints {
  TOOLTIPS = "/tooltips",
}

export const enum LettersGuaranteesEndpoints {
  LETTER_GUARANTEE = "/letter-guarantee",
}

export const enum NotificationsEndpoints {
  NOTIFICATIONS = "/notifications"
}

export const enum MarketplaceEndpoints {
  MATERIALS = "/materials"
}