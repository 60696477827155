import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {IMarketplaceMaterial, IMarketplaceMaterialOffer} from "../../api/marketplace/types";
import {getMarketplaceMaterials} from "../../api/marketplace";
import {AxiosError} from "axios";

export interface MarketplaceState {
    marketplaceData: {
        materials: IMarketplaceMaterial[] | null;
        currentMaterial: IMarketplaceMaterial | null;
        isLoading: boolean,
        error?: string | null;
    };
}

const initialState: MarketplaceState = {
    marketplaceData: {
        materials: null,
        currentMaterial: null,
        isLoading: false,
        error: null,
    },
};

export const fetchAllMaterials = createAsyncThunk<
    IMarketplaceMaterial[],
    undefined,
    { rejectValue: AxiosError<unknown, any> }
>(
    "marketplace/fetchAllMaterials",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await getMarketplaceMaterials();
            return data.data;
        } catch (error: unknown) {
            console.error(error);
            return rejectWithValue(error as AxiosError<unknown, any>);
        }
    }
);


const marketplaceSlice = createSlice({
    name: "marketplace",
    initialState,
    reducers: {
        setCurrentMaterial: (state, action: PayloadAction<IMarketplaceMaterial>) => {
            state.marketplaceData.currentMaterial = action.payload;
        },
        updateCurrentMaterialOffer: (state, action: PayloadAction<IMarketplaceMaterialOffer>) => {
            if (!state.marketplaceData.currentMaterial) return;
            if (!state.marketplaceData.materials) return;

            const updatedOffer = action.payload;
            const copiedCurrentMaterial = {...state.marketplaceData.currentMaterial}
            const offers = copiedCurrentMaterial.offers;

            const index = offers.findIndex((offer) => offer.guid === updatedOffer.guid);
            if (index !== -1) {
                offers[index] = updatedOffer;
            }


            const copiedAllMaterials = [...state.marketplaceData.materials]
            const currentMaterialIndex = copiedAllMaterials.findIndex((material) => material.guid === state?.marketplaceData?.currentMaterial?.guid);
            if (currentMaterialIndex !== -1) {
                copiedAllMaterials[currentMaterialIndex] = copiedCurrentMaterial
            }

            state.marketplaceData.currentMaterial = copiedCurrentMaterial;
            state.marketplaceData.materials = copiedAllMaterials;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMaterials.pending, (state) => {
                state.marketplaceData.isLoading = true;
                state.marketplaceData.error = null;
            })
            .addCase(fetchAllMaterials.fulfilled, (state,action) => {
                state.marketplaceData.isLoading = true;
                state.marketplaceData.materials = action.payload;

                if (action.payload.length > 0) {
                    state.marketplaceData.currentMaterial = action.payload[0];
                }
            })
    },
});

export const { setCurrentMaterial, updateCurrentMaterialOffer } = marketplaceSlice.actions;
export const marketplaceReducer = marketplaceSlice.reducer;
