import {
    Box,
    Flex,
    Grid,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Text,
    Icon,
    Button
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import {ReactComponent as  DeleteIcon} from "../../images/svg/delete/delete-active.svg";
import {ReactComponent as  DisableDeleteIcon} from "../../images/svg/delete/delete-disable.svg";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {useEffect, useMemo, useState} from "react";
import {fetchAllMaterials, setCurrentMaterial, updateCurrentMaterialOffer} from "../../store/slices/marketplace";
import {IMarketplaceMaterial, IMarketplaceMaterialOffer} from "../../api/marketplace/types";
import {CellContext, ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {formatAmount} from "../../helpers/formatAmount";
import {Table} from "../lib/table/Table"

const columnHelper = createColumnHelper<IMarketplaceMaterialOffer>();

type QuantityCellProps = {
    info: CellContext<IMarketplaceMaterialOffer, string>;
};

const QuantityCell = ({ info }: QuantityCellProps) => {
    const dispatch = useAppDispatch();

    const initialQuantity = info.row.original.quantity;

    const [isError, setIsError] = useState<boolean>(false)
    const [value, setValue] = useState(() => {
        const nextNumber = parseFloat(initialQuantity);
        return Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber);
    });

    useEffect(() => {
        const nextNumber = parseFloat(initialQuantity);
        setValue(Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber));
    }, [initialQuantity]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        changeValue(e.target.value)
    }

    const changeValue = (e: string) => {
        const rawValue = e.replace(",", ".");
        const floatValue = parseFloat(rawValue);
        const newValue = Number.isNaN(floatValue) ? "0,00" : formatAmount(floatValue);

        if (newValue !== info.row.original.quantity && floatValue <= parseFloat(info.row.original.amount)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(",", "."))
            copiedOffer.quantity = newValue;
            copiedOffer.totalPrice = formatAmount(
                floatPrice * floatValue
            );
            setIsError(false)
            dispatch(updateCurrentMaterialOffer(copiedOffer));
        } else if(floatValue > parseFloat(info.row.original.amount)){
            setIsError(true)
        }
    };

    const getInputBorderColor = () => {
        if(isError){return "#E8363D"}
        if(value !== "0,00"){return "#AAB0C1"}
        else{return "#E3E7EF"}
    }

    return (
        <Flex key={`${info.row.original.guid}`} alignItems="center" gap="8px">
            <Input
                height="32px"
                width="100px"
                value={value}
                border="1px solid #E3E7EF"
                borderColor={getInputBorderColor()}
                onChange={handleChange}
                onBlur={handleBlur}
                errorBorderColor="#E8363D"

            />
            <Button disabled={value === "0,00"} minWidth="auto" width="32px" backgroundColor="transparent" onClick={() => changeValue("0,00")} height="32px" p="6px" border={`1px solid ${value === "0,00" ? "#E3E7EF" : "#F1868B"}`} borderRadius="6px">
                <Icon width="20px" height="20px" as={value === "0,00" ? DisableDeleteIcon : DeleteIcon} />
            </Button>
        </Flex>
    );
};

export const MarketplaceProducts = () => {

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [searchValue, setSearchValue] = useState<string>("")
    const [filteredMaterials, setFilteredMaterials] = useState<IMarketplaceMaterial[] | undefined>([])

    const { materials, currentMaterial } = useAppSelector(
        (state: RootState) => state.marketplace.marketplaceData
    );

    const handleMaterialClick = (material: IMarketplaceMaterial) => {
        dispatch(setCurrentMaterial(material));
    };

    useEffect(() => {
        if(materials){
            setFilteredMaterials(materials)
        }

    }, [materials]);


    function filterMaterials(){
        if(searchValue.length){
            setFilteredMaterials(materials?.filter((el) => el.name.includes(searchValue)))
        } else {
            if(materials){
                setFilteredMaterials(materials)
            }
        }
    }

    const columns = useMemo<Array<ColumnDef<IMarketplaceMaterialOffer, string>>>(
        () => [
            columnHelper.accessor("name", {
                header: "Поставщик",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.display(
                {
                    header: "Материал",
                    cell: () => <Box>{currentMaterial?.name}</Box>,
                }
            ),
            columnHelper.accessor("article", {
                header: "Артикул",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("description", {
                header: "Описание",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("price", {
                header: "Цена",
                cell: (info) => formatAmount(String(info.getValue())),
            }),
            columnHelper.accessor("quantity",
                {
                    header: "Количество",
                    cell: (info) => (
                        <QuantityCell
                            key={`${currentMaterial?.guid}-${info.row.original.guid}`}
                            info={info}
                        />
                    ),
                }
            ),
            columnHelper.accessor("amount", {
                header: "Остаток",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("totalPrice",
                {
                    header: "Сумма",
                    cell: (info) => info.getValue() || "0,00",
                }
            ),
        ],
        [currentMaterial]
    );

    return (
        <Grid height={"100%"} gridTemplateColumns="360px 1fr" gap="16px">
            <Box>
                <InputGroup width="100%">
                    <InputLeftElement>
                        <SearchIcon color="#838A9D" />
                    </InputLeftElement>
                    <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        rounded="md"
                        borderColor="#E3E7EF"
                        placeholder="Найти"
                    />
                    <InputRightElement onClick={filterMaterials} cursor="pointer" roundedTopRight="md" roundedBottomRight="md" backgroundColor="#E8363D">
                        <SearchIcon color="white" />
                    </InputRightElement>
                </InputGroup>
                <Box rounded="md" mt="16px" padding="8px 16px"  border="1px solid #E3E7EF">
                    <Flex width="100%" alignItems="center" height="48px">
                        <Text
                            width="100%"
                            lineHeight="18px"
                            fontSize="14px"
                            fontWeight={400}
                            color="#8089A2"
                            padding="0 16px"
                            borderLeft="0.5px solid #E3E7EF"
                            borderRight="0.5px solid #E3E7EF"
                            mb="15px"
                        >
                            Материал
                        </Text>
                    </Flex>
                    {filteredMaterials &&
                        filteredMaterials.map( (material, id) =>
                            <Flex key={id} cursor="pointer" onClick={() => handleMaterialClick(material)}
                                  backgroundColor={currentMaterial?.guid == material.guid ? "#F0F3FA" : "white"}
                                  id={material.guid} borderTop="1px solid #E3E7EF" padding="16px" alignItems="center">
                                <Text fontSize="16px" lineHeight="20px">
                                    {material.name}
                                </Text>
                            </Flex>
                        )
                    }

                </Box>
            </Box>
            <Box height={"100%"}>
                <Table
                    dblClickDisabled={true}
                    minHeight={"100%"}
                    style={{"height": "70vh"}}
                    fetchFunction={fetchAllMaterials}
                    columns={columns} data={currentMaterial?.offers || []}> </Table>
            </Box>
        </Grid>

    );
};
