import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  ColumnDef,
  CellContext,
} from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IWorkCompletedActDetail } from "../../api/workCompletedActs/types";
import { setWorkCompletedActDetails } from "../../store/slices/workCompletedActs";
import { IDetail } from "../../api/estimates/types";
import { formatAmount } from "../../helpers/formatAmount";
import { formatWorkActNumbers } from "../../helpers/formatWorkActNumbers";
import styles from "../lib/input/input.module.scss";
import { VatsCellComponent } from "../CreateActBasedOnEstimateComponents/BuildingObjectsBlock/VatCellComponents";

const columnHelper = createColumnHelper<IWorkCompletedActDetail>();

const WorkTypeCell = (info: CellContext<IWorkCompletedActDetail, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

const VatCell = (info: CellContext<IWorkCompletedActDetail, string>) => {
  const [value, setValue] = useState<string | undefined>(info.row.original.vat ?? "")
  const { detailsWorkCompletedAct } = useAppSelector((state: RootState) => state.workCompletedActs);

  const dispatch = useAppDispatch();

  const handleChange = (vat: string) => {
    const rowId = info.row.original.id;
    if (rowId !== undefined && detailsWorkCompletedAct) {
      const updatedMaterials: IWorkCompletedActDetail[] = detailsWorkCompletedAct.map((material) => {
        if (material.id === rowId) {
          return {
            ...material,
            vat: vat,
          };
        }
        return material;
      });

      dispatch(setWorkCompletedActDetails(updatedMaterials));
    }
  }


  useEffect(() => {
    setValue(info.row.original.vat ?? "")
  }, [info.row.original.vat])
  return (
    <VatsCellComponent value={value} onValueChange={handleChange} />
  );
}

export function BuildingObjectsTable() {
  const dispatch = useAppDispatch();

  const { currentWorkCompletedAct,detailsWorkCompletedAct } = useAppSelector((state: RootState) => state.workCompletedActs);
  const { currentEstimate } = useAppSelector((state: RootState) => state.estimates);
  const { currentContract } = useAppSelector((state) => state.contracts);
  const withVat = !((currentContract?.rate_vat === 20) || (currentContract?.rate_vat === 10))

  const findMatchingItem = (actDetail: IWorkCompletedActDetail, estimateDetails: IDetail[] | undefined): IDetail | undefined => {
    if (!estimateDetails) return undefined;
  
    for (const detail of estimateDetails) {
      if (
        actDetail.construction_object_guid === detail.ConstructionObjectID &&
        actDetail.type_of_work_guid === detail.TypeofWorkID
      ) {
        return {...detail, vat: actDetail.vat};
      }
      if (detail.subRows) {
        const found = findMatchingItem(actDetail, detail.subRows);
        if (found) return found;
      }
    }
    return undefined;
  };


  const resultConstructionObjects = useMemo(() => {
    return ((actDetails: IWorkCompletedActDetail[] | undefined, estimateDetails: IDetail[] | undefined) =>
      actDetails?.map((actDetail:IWorkCompletedActDetail) => {
        const matchingItem = findMatchingItem(actDetail, estimateDetails)

        return matchingItem
          ? {
            ...actDetail,
            construction_object_name: matchingItem.ConstructionObjectName,
            type_of_work_name: matchingItem.TypeofWorkName,
            id: matchingItem.TypeofWork
          }
          : actDetail;
      })
    )(currentWorkCompletedAct?.details, currentEstimate?.details);
  }, [currentWorkCompletedAct?.details, currentEstimate?.details]);


  useEffect(() => {
    resultConstructionObjects && dispatch(setWorkCompletedActDetails(resultConstructionObjects));
  }, [dispatch, resultConstructionObjects]);

  const columns = useMemo<Array<ColumnDef<IWorkCompletedActDetail, string>>>(
    () => [
      columnHelper.accessor("construction_object_name", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("type_of_work_name", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantity", {
        header: "Количество",
        cell: (info) => formatWorkActNumbers(info.getValue()),
      }),
      columnHelper.accessor("price", {
        header: "Цена",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("total", {
        header: "Сумма",
        cell: (info) => formatAmount(info.renderValue())
      }),
    ],
    []
  );
  const columnsWithVat = useMemo<Array<ColumnDef<IWorkCompletedActDetail, string>>>(
    () => [
      columnHelper.accessor("construction_object_name", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("type_of_work_name", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantity", {
        header: "Количество",
        cell: (info) => formatWorkActNumbers(info.getValue()),
      }),
      columnHelper.accessor("price", {
        header: "Цена, без НДС",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("total", {
        header: "Сумма, без НДС",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("vat", {
        header: "НДС",
        cell: VatCell,
      }),
    ],
    []
  );

  return <Table data={detailsWorkCompletedAct ?? []} columns={withVat ? columnsWithVat : columns} />;
}
