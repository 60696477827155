import {ChangeEvent, useEffect, useRef, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {useAppSelector} from "../../store";
import {FormikProps, useFormik} from "formik";
import * as Yup from "yup";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { addWorkCompletedAct, attachFilesToSingleWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import {
  OutlineMenuButton,
  PrimaryMenuButton,
} from "../../components/lib/menu/ChakraMenuButton";
import {
  ChakraMenuItem,
  ChakraMenuList,
} from "../../components/lib/menu/ChakraMenuList";
import { BlockTitle } from "../../components/lib/title/Title";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ICreateWorkCompletedActRequest, ICreateWorkCompletedActResponse, IWorkCompletedActDetail } from "../../api/workCompletedActs/types";
import styles from "./create-act.module.scss";
import { Loader } from "../../components/Loader/Loader";
import {CloseFormModal} from "../../components/CloseFormModal/CloseFormModal";
import {SingleEstimateActPrintData} from "../../components/SingleEstimateActPrintData/SingleEstimateActPrintData";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { russianPatternCheck } from "../../helpers/russianPatternCheck";
import { changeEstimate, fetchSingleEstimate, fetchVerifyWorks, handleImportEstimate, resetCurrentImportEstimate } from "../../store/slices/estimates";
import { MainTableBlock } from "../../components/CreateActBasedOnEstimateComponents/MainTableBlock/MainTableBlock";
import { BuildingObjectsBlock } from "../../components/CreateActBasedOnEstimateComponents/BuildingObjectsBlock/BuildingObjectsBlock";
import { CreateActInformationBlock } from "../../components/CreateActBasedOnEstimateComponents/CreateActInformationBlock";
import { downloadExportEstimate } from "../../helpers/downloadExportEstimate";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { IImportEstimateResponse, IVerifyWorkDetailRequest } from "../../api/estimates/types";
import { ConfirmImportModal } from "../../components/CreateActBasedOnEstimateComponents/ConfirmImportModal";
import { WorkActErrorModal } from "../../components/Modal/ModalCenter/WorkActErrorModal";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { ChakraModalProps } from "../../shared/types/modalProps";
import SuccessLogo from "../../images/png/success.png";
import { checkAndSetIsErrorRecursive } from "../../helpers/checkAndSetIsError";

enum ActStatuses {
  WorkingDocument = "Рабочая",
  DraftDocument = "Черновик ЛКП"
}

interface HeaderButtonGroupProps {
  activePage: number;
  changePage: () => void;
  onSubmit: (status: ActStatuses) => void;
  isLoading: boolean;
  closeLoading: (arg: boolean) => void;
  fieldsAreSet: () => boolean;
  onOpen: () => void;
  handleOpenFilesModal: () => void;
  handleExport: () => void;
  handleImport: () => void;
  formik: FormikProps<ICreateWorkCompletedActRequest>;
  isInvalid: boolean;
  setCurrentStatus: (arg:ActStatuses) => void;
  setIsDraftCreate: (arg:boolean) => void
}

export function CreateActBasedOnActPage({ filesModal, setFilesModal, dispatch, goBack }: any) {
  const navigate = useNavigate()
  const { currentEstimate, currentDetails, errorEstimates } = useAppSelector((state) => state.estimates);
  const detailsWithQuantity = currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0);
  const { currentContract } = useAppSelector((state) => state.contracts)
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentVat } = useAppSelector((state) => state.vats);
  const [currentStatus, setCurrentStatus] = useState<ActStatuses | null>(null);
  const [createdGuid, setCreatedGuid] = useState<string | null>(null)
  const [isDraftCreate, setIsDraftCreate] = useState<boolean>(false)

  const [activePage, setActivePage] = useState(1);
  const [isRunSpinner, setIsRunSpinner] = useState(false);
  const [exportSpinnerRun, setExportSpinnerRun] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const { isOpen: isConfirmModalOpen, onClose: isConfirmModalClose, onOpen: onConfirmModalOpen } = useDisclosure();
  const { isOpen: isErrorModalOpen, onClose: onErrorModalClose, onOpen: onErrorModalOpen } = useDisclosure();
  const { isOpen: isSuccessModalOpen, onClose: onSuccesModalClose, onOpen: onSuccesModalOpen } = useDisclosure();

  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangePage = () => {
    if (activePage === 1) {
      if (detailsWithQuantity.length > 0) {
        setExportSpinnerRun(true)
        const resultDetailsArray: IVerifyWorkDetailRequest[] = detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWorkID,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Theamount)
        }))
        currentEstimate && dispatch(fetchVerifyWorks({ id: currentEstimate?.guid, details: resultDetailsArray }))
          .then((res: {
            payload: {
              data: any;
            };
          }) => {
            if (res.payload.data.length === 0) {
              setActivePage(2);
            } else {
              const resultDetails = checkAndSetIsErrorRecursive(currentEstimate.details, res.payload.data)
              const updatedEstimate = { ...currentEstimate, details: resultDetails };
              dispatch(changeEstimate(updatedEstimate))
              onErrorModalOpen()
            }
          })
          .finally(() => setExportSpinnerRun(false))
      } else {
        setActivePage(2)
      }
    } else {
      setActivePage(1)
    }
  };


  const transformDetails = (details: IWorkCompletedActDetail[]) =>
    details?.map((detail: IWorkCompletedActDetail) => ({
      ConstructionObjectID: detail.construction_object_guid,
      TypeofWork: detail.type_of_work_guid,
      Price: Number(detail.price),
      Total: Number(detail.total),
      quantity: Number(detail.quantity)
    }));

  const handleOpenFilesModal = () => {
    setFilesModal(true);
  }

  const fileAttach = (id: string) => {
    dispatch(attachFilesToSingleWorkCompletedAct({ id: id, files: files }))
      .catch((e: Error) => {
        console.error("Error: ", e);
      });
  };

  const formik: FormikProps<ICreateWorkCompletedActRequest> =
    useFormik<ICreateWorkCompletedActRequest>({
      initialValues: {
        invoice_number: currentWorkCompletedAct?.invoice_number ?? "",
        invoice_date: currentWorkCompletedAct?.invoice_date ?? "",
        act_number: currentWorkCompletedAct?.act_number ?? "",
        act_date: currentWorkCompletedAct?.act_date ?? "",
        sf_number: currentWorkCompletedAct?.sf_number ?? "",
        sf_date: currentWorkCompletedAct?.sf_date ?? "",
        manager_name: currentWorkCompletedAct?.manager_name ?? "",
        manager_name_power_of_attorney: currentWorkCompletedAct?.manager_name_power_of_attorney ?? "",
        chief_accountant: currentWorkCompletedAct?.chief_accountant ?? "",
        chief_accountant_power_of_attorney: currentWorkCompletedAct?.chief_accountant_power_of_attorney ?? "",
        date_from: currentWorkCompletedAct?.date_from ?? "",
        date_to: currentWorkCompletedAct?.date_to ?? "",
        status: currentWorkCompletedAct?.status ?? "",
        contract_id: currentWorkCompletedAct?.contract_id ?? 0,
        estimate_id: currentWorkCompletedAct?.estimate_id ?? 0,
        details: detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWork,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Theamount),
          vat: detail.vat
        })),
        advance_payments: [],
        executive_documentation: currentWorkCompletedAct?.executive_documentation ?? "",
        vat: currentVat ?? "",
      },
      validationSchema: Yup.object({
        invoice_number: Yup.string().required(),
        invoice_date: Yup.string().required(),
        act_number: Yup.string().required(),
        act_date: Yup.string().required(),
        sf_number: Yup.string().required(),
        sf_date: Yup.string().required(),
        manager_name: Yup.string().required(),
        manager_name_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
              Yup.string().required() : Yup.number().positive().nullable()
        ),
        chief_accountant: Yup.string().required(),
        chief_accountant_power_of_attorney: Yup.lazy((value) =>
            typeof value === 'string' ?
                Yup.string().required() : Yup.number().positive().nullable()
        ),
        date_from: Yup.string().required(),
        date_to: Yup.string().required(),
        status: Yup.string().required(),
        contract_id: Yup.number().required(),
        estimate_id: Yup.number().required(),
        details: Yup.array().required(),
      }),
      onSubmit: () => dispatch(addWorkCompletedAct(formik.values)),
      enableReinitialize: false,
      validateOnBlur: false,
      validateOnMount: false,
    });

    useEffect(() => {
      formik.setFieldValue("vat", currentVat)
    }, [currentVat])
  
  
    useEffect(() => {
      formik.values.details = detailsWithQuantity.map((detail) => ({
        ConstructionObjectID: detail.ConstructionObjectID,
        TypeofWork: detail.TypeofWork,
        quantity: Number(detail.quantityinthedocument),
        Price: Number(detail.Price),
        Total: Number(detail.Theamount,),
        vat: detail.vat
      }))
    }, [detailsWithQuantity, currentDetails])

  const fieldsAreSet = () => {
    const requiredFieldsFilled =
      formik.values.invoice_number.trim() !== '' &&
      formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' &&
      formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' &&
      formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' &&
      formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' &&
      formik.values.date_to !== '' &&
      russianPatternCheck(formik.values.manager_name) &&
      russianPatternCheck(formik.values.chief_accountant);

    const advancePaymentsFilled = formik.values.advance_payments ?
      formik.values.advance_payments.every(item => item.date !== "" && item.name !== "") :
      true;

    return requiredFieldsFilled && advancePaymentsFilled;
  };

  useEffect(() => {
    const requiredFieldsFilled =
    formik.values.invoice_number.trim() !== '' &&
    formik.values.invoice_date !== '' &&
    formik.values.act_number.trim() !== '' &&
    formik.values.act_date !== '' &&
    formik.values.sf_number.trim() !== '' &&
    formik.values.sf_date !== '' &&
    formik.values.manager_name.trim() !== '' &&
    formik.values.chief_accountant.trim() !== '' &&
    formik.values.date_from !== '' &&
    formik.values.date_to !== '' &&
    russianPatternCheck(formik.values.manager_name) &&
    russianPatternCheck(formik.values.chief_accountant) &&
    formik.values.executive_documentation !== "";
    

  const advancePaymentsFilled = formik.values.advance_payments ?
    formik.values.advance_payments.every(item => (item.date !== "" && item.name !== "") || (item.date === "" && item.name === "")) :
    true;

  setIsInvalid(requiredFieldsFilled && advancePaymentsFilled);
  }, [formik.values])

  useEffect(() => {
    formik.setFieldValue("invoice_number", currentWorkCompletedAct?.invoice_number ?? "")
    formik.setFieldValue("invoice_date", currentWorkCompletedAct?.invoice_date ?? "")
    formik.setFieldValue("act_number", currentWorkCompletedAct?.act_number ?? "")
    formik.setFieldValue("act_date", currentWorkCompletedAct?.act_date ?? "")
    formik.setFieldValue("sf_number", currentWorkCompletedAct?.sf_number ?? "")
    formik.setFieldValue("sf_date", currentWorkCompletedAct?.sf_date ?? "")
    formik.setFieldValue("manager_name", currentWorkCompletedAct?.manager_name ?? "")
    formik.setFieldValue("manager_name_power_of_attorney", currentWorkCompletedAct?.manager_name_power_of_attorney ?? "")
    formik.setFieldValue("chief_accountant", currentWorkCompletedAct?.chief_accountant ?? "")
    formik.setFieldValue("chief_accountant_power_of_attorney", currentWorkCompletedAct?.chief_accountant_power_of_attorney ?? "")
    formik.setFieldValue("date_from", currentWorkCompletedAct?.date_from ?? "")
    formik.setFieldValue("date_to", currentWorkCompletedAct?.date_to ?? "")
    formik.setFieldValue("contract_id", currentWorkCompletedAct?.contract_id ?? "")
    formik.setFieldValue("estimate_id", currentWorkCompletedAct?.estimate_id ?? "")
    formik.setFieldValue("details", currentWorkCompletedAct?.details ? transformDetails(currentWorkCompletedAct.details) : [])
    formik.setFieldValue("advance_payments", currentWorkCompletedAct?.advance_payments ?? [])
    formik.setFieldValue("executive_documentation", currentWorkCompletedAct?.executive_documentation ?? "")
  },[currentWorkCompletedAct])

  const onSubmit = (status: ActStatuses, acceptCreate?: boolean) => {
    if (currentContract && currentEstimate) {
      if (errorEstimates.length > 0 && !acceptCreate) {
        onErrorModalOpen();
      } else {
        setIsRunSpinner(true);
        dispatch(
          addWorkCompletedAct({
            ...formik.values,
            status,
          })
        )
          .then((res: { payload: ICreateWorkCompletedActResponse; }) => {
            if (!!files.length) {
              fileAttach((res.payload as ICreateWorkCompletedActResponse)?.data.id);
            }
            if (isDraftCreate) {
              setCreatedGuid(`${res.payload.data.id}/draft`)
            } else {
              setCreatedGuid(`${res.payload.data.id}`)
            }
            onSuccesModalOpen()
          })
          .catch((e: Error) => {
            console.error('Error: ', e);
          })
          .finally(() => {
            setIsRunSpinner(false);
          })
      }
    }
  };

  const handleExportClick = () => {
    currentEstimate && downloadExportEstimate(currentEstimate.id, detailsWithQuantity.map((detail) => ({
      id: detail.TypeofWork,
      quantity: Number(detail.quantityinthedocument),
      Price: Number(detail.Price),
      Total: Number(detail.Theamount),
    })), setExportSpinnerRun)
  }

  const handleImportClick = () => {
    fileInputRef.current?.click();
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    (currentEstimate && file) && dispatch(handleImportEstimate({ id: currentEstimate?.id, file }))
      .then((res: { payload: IImportEstimateResponse; }) => {
        const total = sumTheAmountForParents(res.payload.data)
        const updatedEstimate = { ...currentEstimate, details: total };
        dispatch(changeEstimate(updatedEstimate))
      })
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      })
  };

  useEffect(() => {
    if (!currentWorkCompletedAct){
      const remove = "/create-act-based-act"
      goBack(location.pathname.slice(0, -remove.length))
    }
  }, [currentWorkCompletedAct, goBack, location.pathname])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentImportEstimate())
    }
  }, [])

  function sumTheAmountForParents(rows: any): any[] {
    function calculateSum(rows: any): any[] {
      return rows.map((row: any) => {
        let sum = Number(row.Theamount);
        if (row.subRows && row.subRows.length > 0) {
          const updatedSubRows = calculateSum(row.subRows);
          sum += updatedSubRows.reduce((acc: number, cur: any) => acc + Number(cur.Theamount), 0);
          return { ...row, subRows: updatedSubRows, Theamount: sum.toFixed(3) };
        }
        return { ...row, Theamount: sum.toFixed(3) };
      });
    }
    return calculateSum(rows);
  }

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} padding="0 24px 24px 24px">
        <Flex direction="column">
          <Flex
            direction="column"
            gap={4}
            position="sticky"
            top={0}
            paddingTop="24px"
            zIndex={2}
            bg="#F6F8FC"
            pb={4}
          >
            <Breadcrumb
              fontSize={14}
              separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="/my-documents/contracts">Договоры</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}`}>
                  {currentContract?.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage color="#8089A2">
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}/create-act-based-act`}>
                  Создать акт на основе акта
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Flex justify="space-between" align="center">
              <Flex align="center" gap={4}>
                {activePage === 1 && <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />}
                <BlockTitle>Создать акт на основе акта</BlockTitle>
              </Flex>
              <HeaderButtonGroup
                handleOpenFilesModal={handleOpenFilesModal}
                activePage={activePage}
                changePage={handleChangePage}
                onSubmit={onSubmit}
                isLoading={isRunSpinner}
                closeLoading={setIsRunSpinner}
                fieldsAreSet={fieldsAreSet}
                onOpen={onOpen}
                handleExport={handleExportClick}
                handleImport={onConfirmModalOpen}
                formik={formik}
                isInvalid={isInvalid}
                setCurrentStatus={setCurrentStatus}
                setIsDraftCreate={setIsDraftCreate}
              />
              <input type="file" accept=".xls,.xlsx" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
            </Flex>
          </Flex>

          {activePage === 1 ? (
            <>
            <MainTableBlock />
            <Box>
                <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RW} />
            </Box>
          </>
          ) : (
            <SecondStage formik={formik} />
          )}
        </Flex>
      </Flex>
      <CloseFormModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        setFilesOnCreate={setFiles}
      />
      <FullScreenWaitingModal openModal={exportSpinnerRun} />
      <ConfirmImportModal isOpen={isConfirmModalOpen} onClose={isConfirmModalClose} inputRef={fileInputRef} />
      <WorkActErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} setActivePage={setActivePage} activePage={activePage} submitFunction={onSubmit} currentStatus={currentStatus} />
      <SuccessModal createdGuid={createdGuid} isOpen={isSuccessModalOpen} onClose={() => {
        onSuccesModalClose()
        if (createdGuid !== null) {
          navigate(`/my-documents/contracts/work-completed-act/${createdGuid}`)
        } else {
          navigate("/documents-in-progress/completed-work-acts")
        }
      }} isDraft={isDraftCreate} />
    </MainLayout>
  );
}

const SecondStage = (props: {
  formik: FormikProps<ICreateWorkCompletedActRequest>;
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="column" gap={10}>
        <CreateActInformationBlock formik={props.formik} />
        <BuildingObjectsBlock />
        <SingleEstimateActPrintData formik={props.formik}/>
        <Box>
          <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RW} />
        </Box>
      </Flex>
    </Flex>
  );
};

const HeaderButtonGroup = (props: HeaderButtonGroupProps) => {
  const { handleOpenFilesModal, formik, isInvalid, setCurrentStatus, setIsDraftCreate } = props;

  return (
    <ButtonGroup spacing={3}>
      {props.activePage === 1 ? (
        <>
          <Menu>
            <OutlineMenuButton rightIcon={<ChevronDownIcon />}>
              Excel
            </OutlineMenuButton>
            <ChakraMenuList
              list={[
                {
                  title: "Загрузить текущее выполнение",
                  onClick: () => props.handleImport()
                },
                {
                  title: "Выгрузить текущее выполнение",
                  onClick: () => props.handleExport()
                },
              ]}
            />
          </Menu>
          <PrimaryButton onClick={props.changePage}>Далее</PrimaryButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={props.changePage}>Назад</OutlineButton>
          <OutlineButton onClick={props.onOpen}>Отмена</OutlineButton>
          <OutlineButton
            leftIcon={<Icon as={Document} fill={"#E8363D"} />}
            gap={2}
            onClick={handleOpenFilesModal}
          >
              Файлы
            </OutlineButton>
            {!props.isLoading && (
              <Menu>
                <PrimaryMenuButton
                  rightIcon={<ChevronDownIcon />}
                  className={props.isInvalid ? "" : styles.disabled}
                >
                  Записать
                </PrimaryMenuButton>
                <MenuList border="1px solid #E3E7EF" p={1}>
                  <Divider />
                  <ChakraMenuItem
                    onClick={() => {
                      setIsDraftCreate(true)
                      setCurrentStatus(ActStatuses.DraftDocument)
                      props.onSubmit(ActStatuses.DraftDocument)
                    }}
                  >
                    Записать черновик
                  </ChakraMenuItem>
                  <ChakraMenuItem
                    onClick={() => {
                      setIsDraftCreate(false)
                      setCurrentStatus(ActStatuses.WorkingDocument)
                      props.onSubmit(ActStatuses.WorkingDocument)
                    }}
                    isDisabled={formik.values.details.length === 0}
                  >
                    Записать рабочий документ
                  </ChakraMenuItem>
                </MenuList>
              </Menu>
            )}

            {props.isLoading && <Loader isLoading={props.isLoading} />}

        </>
      )}
    </ButtonGroup>
  );
};

interface SuccessModalProps extends ChakraModalProps {
  createdGuid: string | null;
  isDraft: boolean
}

const SuccessModal = ({ onClose, isOpen, createdGuid, isDraft }: SuccessModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={SuccessLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
             {isDraft ? "Черновик создан" : "Документ отправлен"}
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={() => {
            onClose()
            if (createdGuid !== null) {
              navigate(`/my-documents/contracts/work-completed-act/${createdGuid}`)
            } else {
              navigate("/documents-in-progress/completed-work-acts")
            }
          }}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
