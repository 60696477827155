export function checkAndSetIsErrorRecursive(array1, array2) {
  let newArray = [];
  for (let i = 0; i < array1.length; i++) {
    const item1 = array1[i];
    let clonedItem = { ...item1 };
    clonedItem.isError = array2.some((item2) => {
      return item1.TypeofWork === item2.TypeOfWork
    });

    if (item1.subRows && item1.subRows.length > 0) {
      let clonedSubRows = JSON.parse(JSON.stringify(item1.subRows));
      clonedItem.subRows = checkAndSetIsErrorRecursive(clonedSubRows, array2);
    }
    newArray.push(clonedItem);
  }
  return newArray;
}
