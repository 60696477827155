import { Outlet } from "react-router-dom";
import { Flex, Spacer } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { RequireAuth } from "../../hoc/RequireAuth";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { myDocumentsPageTabs } from "../../shared/mock/myDocumentsPageData";
import {MarketplaceProducts} from "../../components/MarketplaceProducts/MarketplaceProducts";

export const MarketplacePage = () => {
    return (
        <RequireAuth>
            <MainLayout >
                <Flex height={"100%"} backgroundColor="white" overflowY="scroll" maxH="100%" p={6} direction="column" gap={4} minH="full">
                    <Title>Маркетплейс</Title>
                    <MarketplaceProducts></MarketplaceProducts>
                </Flex>
            </MainLayout>
        </RequireAuth>
    );
};
